import React from 'react';
import {
  Confirm as ReactAdminConfirm,
  CheckboxGroupInput,
  TextInput,
  BooleanInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  required,
  REDUX_FORM_NAME,
} from 'react-admin';
import {
  isValidUrl,
  containsCIDRBlockZero,
  containsCIDRBlockLessThan16,
} from 'util/validation';
import get from 'util/get';
import ResultModal from 'CustomComponents/Modal';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import RegenerateApiAccessKey from 'CustomComponents/Buttons/RegenerateApiKey';
import { isValidCommaSeparatedIPList } from 'util/validation';

const allowHttp = true;
const validateWhitelistUrl = url => url && isValidUrl(url, allowHttp);

const displayWhiteListHelperText = ({ apiAccessErrors, apiAccessValues }) => {
  if (!(apiAccessErrors && apiAccessErrors.whitelist)) {
    if (!apiAccessValues.whitelist) {
      return 'Your whitelist is empty; all addresses are blocked';
    }
    return (
      containsCIDRBlockZero(apiAccessValues.whitelist) ||
      containsCIDRBlockLessThan16(apiAccessValues.whitelist)
    );
  }
  return apiAccessErrors.whitelist;
};

const ApiAccessSettings = ({
  apiTypes,
  classes,
  syncErrors,
  isResultDialogOpen,
  isConfirmDialogOpen,
  handleDialogOpen,
  handleDialogClose,
  handleRegenerateApiKey,
  resultDialogContent,
  hasApiKey,
  permissions,
}) => {
  const isSuper = permissions && permissions.includes('SUPER');
  return (
    <FormDataConsumer>
      {({ formData, dispatch }) => {
        const apiTypeId = get(['apiType'], formData) || 'REFERALL';
        const apiType = get([apiTypeId], apiTypes) || {};

        return (
          <div className={classes.column}>
            {isSuper && (
              <ReferenceInput
                label="API Type"
                source="apiType"
                reference="ApiType"
                style={{ width: '100%' }}
                validate={required()}
                defaultValue={'REFERALL'}
                onChange={(_, newApiTypeId) =>
                  dispatch(
                    change(
                      REDUX_FORM_NAME,
                      'apiAccess.webhookUrlDefault',
                      get([newApiTypeId, 'webhookUrlDefault'], apiTypes)
                    )
                  )
                }
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            )}
            <BooleanInput
              label={'Api Access Enabled'}
              source={'apiAccess.enabled'}
            />
            {get(['apiAccess', 'enabled'], formData) ? (
              <React.Fragment>
                <RegenerateApiAccessKey
                  hasApiKey={hasApiKey}
                  onClick={() => handleDialogOpen('CONFIRM')}
                />
                <TextInput
                  label={'IP Whitelist (comma-separated list)'}
                  source={'apiAccess.whitelist'}
                  validate={isValidCommaSeparatedIPList}
                  helperText={displayWhiteListHelperText({
                    apiAccessErrors: syncErrors.apiAccess,
                    apiAccessValues: formData.apiAccess,
                  })}
                />
                <TextInput
                  label="Webhook URL (if required)"
                  source={
                    apiType.webhookReadonly &&
                    formData.apiAccess.webhookUrlDefault
                      ? 'apiAccess.webhookUrlDefault'
                      : 'apiAccess.webhookUrl'
                  }
                  disabled={apiType.webhookReadonly}
                  validate={validateWhitelistUrl}
                  helperText={
                    apiType.webhookSignatureIncluded &&
                    formData.apiAccess.webhookSignatureKey &&
                    ((syncErrors.apiAccess &&
                      syncErrors.apiAccess.webhookUrl) ||
                      `Signature: ${formData.apiAccess.webhookSignatureKey}`)
                  }
                />
                {isSuper && (
                  <CheckboxGroupInput
                    label={'API Permissions'}
                    source="apiAccess.permissions"
                    style={{ width: '15vw' }} // force the options to be in a single column
                    choices={[
                      {
                        id: 'createUserPermission',
                        name: 'Create/Edit User',
                      },
                      {
                        id: 'submitActivityPermission',
                        name: 'Submit User Activity',
                      },
                      {
                        id: 'submitHealthMetricPermission',
                        name: 'Submit User Health Metric',
                      },
                      {
                        id: 'receiveActivityPermission',
                        name: 'Receive User Activity',
                      },
                      {
                        id: 'receiveHealthMetricPermission',
                        name: 'Receive User Health Metric',
                      },
                      {
                        id: 'receiveUserRegisteredPermission',
                        name: 'Receive User Registration',
                      },
                      {
                        id: 'receiveUserAppOpenedPermission',
                        name: 'Receive User Opened App',
                      },
                    ]}
                  />
                )}

                {apiType.documentationUrl && (
                  <blockquote className={classes.blockquote}>
                    <p>
                      {'API Documentation can be found at '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={apiType.documentationUrl}
                      >
                        {apiType.documentationUrl}
                      </a>
                    </p>
                  </blockquote>
                )}
                <ReactAdminConfirm
                  isOpen={isConfirmDialogOpen}
                  title="Generate API Key"
                  content="Are you sure you want to generate a new API key? This will replace the current key."
                  onConfirm={() => handleRegenerateApiKey()}
                  onClose={() => handleDialogClose('CONFIRM')}
                />
                <ResultModal
                  isOpen={isResultDialogOpen}
                  title="Your New API Key"
                  content={resultDialogContent()}
                  onClose={() => handleDialogClose('RESULT')}
                />
              </React.Fragment>
            ) : null}
          </div>
        );
      }}
    </FormDataConsumer>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    apiTypes: get(['admin', 'resources', 'ApiType', 'data'], state) || {},
  };
};

export default connect(mapStateToProps)(ApiAccessSettings);
