import gql from 'graphql-tag';

const contentType = file => file.type;

const requestPutUrl = async client => {
  const response = await client.mutate({
    mutation: gql`
      mutation CreateActivityMediaPutUrl {
        url: createActivityMediaPutUrl
      }
    `,
  });
  return response.data.url;
};

const putFile = (url, file) => {
  return fetch(url, {
    headers: { 'Content-Type': contentType(file) },
    body: file,
    method: 'PUT',
  });
};

const removeDomain = url => {
  return url.replace(/^.*?amazonaws.com\//, '');
};

const removeQuery = url => {
  return url.replace(/\?.*/, '');
};

const getKey = url => removeDomain(removeQuery(url));

const uploadMedia = async ({ client, file }) => {
  const url = await requestPutUrl(client);
  await putFile(url, file);
  return getKey(url);
};

export default uploadMedia;
