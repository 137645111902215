import React from 'react';
import {
  TextInput,
  required,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
} from 'react-admin';
import get from 'util/get';

const DeviceTrackedStorefront = ({ classes, device }) => {
  const dataExtractionStrategy = [
    { id: 'GET_PARAM', name: 'Get Param' },
    { id: 'PATTERN_SEARCH', name: 'Pattern Search' },
  ];

  const deviceAccessor = device.toLowerCase();
  const getOrderIdStrategy = formData =>
    get(['storefront', deviceAccessor, 'orderId', 'strategy'], formData);

  const getTotalStrategy = formData =>
    get(['storefront', deviceAccessor, 'total', 'strategy'], formData);

  const isEnabled = formData =>
    Boolean(get(['storefront', deviceAccessor, 'enabled'], formData));

  return (
    <div className={classes.column}>
      <BooleanInput
        label={`${device} Storefront Enabled`}
        source={`storefront.${deviceAccessor}.enabled`}
      />
      <FormDataConsumer>
        {({ formData }) => (
          <React.Fragment>
            <TextInput
              label={`${device} Storefront URL`}
              source={`storefront.${deviceAccessor}.url`}
              validate={isEnabled(formData) && required()}
              disabled={!isEnabled(formData)}
            />
            <TextInput
              label={`${device} Order ID URL`}
              source={`storefront.${deviceAccessor}.orderId.url`}
              validate={isEnabled(formData) && required()}
              disabled={!isEnabled(formData)}
            />
            <SelectInput
              label={`${device} Order ID Type`}
              source={`storefront.${deviceAccessor}.orderId.strategy`}
              choices={dataExtractionStrategy}
              validate={isEnabled(formData) && required()}
              defaultValue="GET_PARAM"
              disabled={!isEnabled(formData)}
            />
            {getOrderIdStrategy(formData) === 'GET_PARAM' ? (
              <TextInput
                label={`${device} Order Field`}
                source={`storefront.${deviceAccessor}.orderId.field`}
                validate={isEnabled(formData) && required()}
                disabled={!isEnabled(formData)}
              />
            ) : (
              <TextInput
                label={`${device} Order ID Pattern`}
                source={`storefront.${deviceAccessor}.orderId.pattern`}
                validate={isEnabled(formData) && required()}
                disabled={!isEnabled(formData)}
              />
            )}
            <TextInput
              label={`${device} Total URL`}
              source={`storefront.${deviceAccessor}.total.url`}
              validate={isEnabled(formData) && required()}
              disabled={!isEnabled(formData)}
            />
            <SelectInput
              label={`${device} Total Type`}
              source={`storefront.${deviceAccessor}.total.strategy`}
              choices={dataExtractionStrategy}
              validate={isEnabled(formData) && required()}
              defaultValue="GET_PARAM"
              disabled={!isEnabled(formData)}
            />
            {getTotalStrategy(formData) === 'GET_PARAM' ? (
              <TextInput
                label={`${device} Total Field`}
                source={`storefront.${deviceAccessor}.total.field`}
                validate={isEnabled(formData) && required()}
                disabled={!isEnabled(formData)}
              />
            ) : (
              <TextInput
                label={`${device} Total Pattern`}
                source={`storefront.${deviceAccessor}.total.pattern`}
                validate={isEnabled(formData) && required()}
                disabled={!isEnabled(formData)}
              />
            )}
          </React.Fragment>
        )}
      </FormDataConsumer>
    </div>
  );
};

export default DeviceTrackedStorefront;
