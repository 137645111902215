import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/icons/GetApp';
import get from 'util/get';
import Empty from 'CustomComponents/Empty';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import {
  CardActions,
  downloadCSV,
  Button,
  ArrayField,
  Datagrid,
  DateField,
  TextField,
} from 'react-admin';

const MoneyField = ({ source, record = {} }) => {
  const money = record[source];
  if (!money) return;
  const formatted = money.amount;
  return <span>{formatted}</span>;
};

const styles = {
  actionBar: {
    minHeight: '64px',
    alignItems: 'center',
  },
};

class Orders extends React.Component {
  downloadOrderCsv = () => {
    const org = this.props.record;
    const { orders } = org;
    const csvData = orders.map(order => {
      return {
        'User ID': get('user.id', order),
        'User Full Name': get('user.fullName', order),
        'User Email': get('user.email', order),
        'Order Date': get('orderDate', order),
        'Order ID': get('id', order),
        'Order Total': get('orderTotal.amount', order),
      };
    });
    const date = new Date().toISOString().slice(0, 10);
    const csv = convertToCSV({
      data: csvData,
      fields: Object.keys(csvData[0]),
    });
    return downloadCSV(csv, `orders-${org.id}-${date}`);
  };

  render() {
    const { classes } = this.props;
    return get('record.orders.length', this.props) > 0 ? (
      <div>
        <CardActions className={classes.actionBar}>
          <Button label="Export" onClick={this.downloadOrderCsv}>
            <Icon />
          </Button>
        </CardActions>
        <ArrayField record={this.props.record} source="orders">
          <Datagrid>
            <TextField source="user.id" label="User ID" />
            <TextField source="user.fullName" label="User Full Name" />
            <TextField source="user.email" label="User Email" />
            <DateField source="orderDate" label="Order Date" />
            <TextField source="id" label="Order ID" />
            <MoneyField source="orderTotal" label="Order Total" />
          </Datagrid>
        </ArrayField>
      </div>
    ) : (
      <Empty name="Orders" />
    );
  }
}

export default withStyles(styles)(Orders);
