import React, { Component } from 'react';
import { Button, showNotification, withDataProvider } from 'react-admin';
import { connect } from 'react-redux';
import { getFormSyncErrors } from 'redux-form';
import { withStyles } from '@material-ui/core';
import { ContentCopy } from '@material-ui/icons';
import copyToClipboard from 'util/copyToClipboard';
import get from 'util/get';
import SimpleStorefront from './SimpleStorefront';
import TrackedStorefront from './TrackedStorefront';
import ApiAccessSettings from './ApiAccessSettings';

const styles = {
  form: { width: '100%', height: '55vh' },
  contentBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
  },
  blockquote: {
    margin: '24px 0',
    padding: '4px 24px',
    borderLeft: '5px solid #ffe564',
    backgroundColor: 'rgba(255,229,100,0.2)',
  },
};

const initialState = Object.freeze({
  data: null,
  isConfirmOpen: false,
  isResultOpen: false,
});

class Technical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasApiKey:
        this.props.record.apiAccess && this.props.record.apiAccess.hasApiKey,
      ...initialState,
    };
  }

  isDialogOpen = type => {
    if (type === 'CONFIRM') return Boolean(this.state.isConfirmOpen);
    if (type === 'RESULT') return Boolean(this.state.isResultOpen);
  };

  handleDialogOpen = type => {
    console.trace('handleDialogOpen', type, this);
    if (type === 'CONFIRM') return this.setState({ isConfirmOpen: true });
    if (type === 'RESULT') return this.setState({ isResultOpen: true });
  };

  handleDialogClose = type => {
    if (type === 'CONFIRM') return this.setState({ isConfirmOpen: false });
    if (type === 'RESULT') {
      this.setState({ isResultOpen: false, hasApiKey: true });
      this.reset();
    }
  };

  handleRegenerateApiKey = () => {
    this.handleDialogClose('CONFIRM');
    const { dataProvider, record, showNotification } = this.props;
    return dataProvider('GENERATE_API_KEY', 'Organisation', {
      organisationId: record.id,
    })
      .then(result => {
        if (!result.data) {
          return showNotification(
            'Unable to regenerate api access key, please try again later',
            'warning'
          );
        }
        this.setState({ data: result.data });
        return this.handleDialogOpen('RESULT');
      })
      .catch(e => {
        console.error(e);
        showNotification(`${e}`, 'warning');
      });
  };

  resultDialogContent = () => {
    const apiKey = this.state.data;
    const { showNotification } = this.props;
    return (
      <React.Fragment>
        <span
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Button
            color="secondary"
            variant="contained"
            onClick={() => copyToClipboard({ text: apiKey, showNotification })}
          >
            <ContentCopy />
          </Button>
          <span style={{ paddingLeft: 10, paddingRight: 32 }}>{apiKey}</span>
        </span>
        <p style={{ color: 'red' }}>
          {
            'Ensure you have recorded this key, as you will not be able to retrieve it again.'
          }
        </p>
      </React.Fragment>
    );
  };

  reset = () => {
    this.setState(initialState);
  };

  renderInternal() {
    const storefrontType =
      get(['record', 'storefront', 'type'], this.props) || 'NONE';

    const boundProps = {
      isDialogOpen: this.isDialogOpen,
      isConfirmDialogOpen: this.isDialogOpen('CONFIRM'),
      isResultDialogOpen: this.isDialogOpen('RESULT'),
      handleDialogOpen: this.handleDialogOpen,
      handleDialogClose: this.handleDialogClose,
      handleRegenerateApiKey: this.handleRegenerateApiKey,
      resultDialogContent: this.resultDialogContent,
      hasApiKey: this.state.hasApiKey,
      ...this.props,
    };

    switch (storefrontType) {
      case 'TRACKED':
        return <TrackedStorefront {...boundProps} />;
      case 'SIMPLE':
        return <SimpleStorefront {...boundProps} />;
      default:
        return <ApiAccessSettings {...boundProps} />;
    }
  }

  render() {
    return <div>{this.renderInternal()}</div>;
  }
}

const mapStateToProps = state => ({
  syncErrors: getFormSyncErrors('record-form')(state),
});

export default connect(mapStateToProps, { showNotification })(
  withDataProvider(withStyles(styles)(Technical))
);
