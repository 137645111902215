import React from 'react';
import PropTypes from 'prop-types';
import ErrorModal from 'CustomComponents/Modal';
import Confirm from 'CustomComponents/Confirm';
import CsvUploader from '../CsvUploader';

const emailRegEx = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i;

const isValidEmail = input => {
  return emailRegEx.test(input);
};

const initialState = Object.freeze({
  data: [],
  error: null,
  confirmationAccepted: false,
});

class UserCsvInviter extends React.Component {
  static get defaultProps() {
    return {
      show: true,
      label: 'Invite Users',
      tite: `Invite Users`,
    };
  }

  static get propTypes() {
    return {
      show: PropTypes.boolean,
      label: PropTypes.string,
      title: PropTypes.string,
      onSuccess: PropTypes.func.required,
    };
  }

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  reset = () => {
    this.setState(initialState);
  };

  get confirmDialogIsOpen() {
    return Boolean(this.state.data.length && !this.state.confirmationAccepted);
  }
  get errorDialogIsOpen() {
    return Boolean(this.state.error);
  }

  get dialogErrorText() {
    return (
      this.state.error &&
      `Unable to upload CSV file due to errors: ${this.state.error}`
    );
  }

  get confirmModalContent() {
    return (
      <React.Fragment>
        {this.state.data.length} unique emails found:
        <ul>
          {this.state.data.map(email => (
            <li key={email}>{email}</li>
          ))}
        </ul>
      </React.Fragment>
    );
  }

  validate(csv) {
    if (csv.length > 50) {
      return {
        data: [],
        error: `Maximum of 50 emails per request exceeded (${csv.length} entries found)`,
      };
    }
    const dataSet = new Set();
    let error = 'No data found';
    for (var record of csv) {
      // Ignore empty lines.
      if (!record) {
        continue;
      }
      if (record.length > 1) {
        error = `Invalid record "${record}" found`;
        break;
      }
      const [email] = record;
      if (!isValidEmail(email)) {
        error = `Invalid email format "${email}" found`;
        break;
      }
      error = null;
      dataSet.add(email);
    }
    return {
      data: [...dataSet],
      error,
    };
  }

  handleError = error => {
    this.setState({
      error,
    });
  };

  handleCsv = csv => {
    const { data, error } = this.validate(csv);
    this.setState({ data, error });
  };

  onSuccess = () => {
    this.props.onSuccess(this.state.data);
    this.reset();
  };

  render() {
    return (
      <React.Fragment>
        <CsvUploader
          onSuccess={this.handleCsv}
          onError={this.handleError}
          show={this.props.show}
          label={this.props.label}
        />
        <Confirm
          isOpen={this.confirmDialogIsOpen}
          title={this.props.title}
          content={this.confirmModalContent}
          onConfirm={this.onSuccess}
          onClose={this.reset}
        />
        <ErrorModal
          isOpen={this.errorDialogIsOpen}
          title={'Error'}
          content={this.dialogErrorText}
          onClose={this.reset}
        />
      </React.Fragment>
    );
  }
}

export default UserCsvInviter;
