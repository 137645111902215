export const organisationTypeMapping = [
  { id: 'HEALTH_AUTHORITY', name: 'Health Authority' },
  { id: 'CLINICIAN', name: 'Clinician' },
];

export const rewardProgrammeMapping = [
  { id: 'NONE', name: 'None' },
  { id: 'EYW', name: 'EYW' },
];

export const storefrontMapping = [
  { id: 'NONE', name: 'None' },
  { id: 'SIMPLE', name: 'Simple' },
  { id: 'TRACKED', name: 'Tracked' },
];

export const eywStorefrontMapping = [
  { id: 'TRACKED', name: 'Tracked' },
];



export const statusMapping = [
  { id: 'ACTIVE', name: 'Active' },
  { id: 'DRAFT', name: 'Draft' },
  { id: 'DISABLED', name: 'Disabled' },
];

export const mapValue = (map, value) => {
  const mapping = map.find(element => element.id === value);
  if (mapping) {
    return mapping.name;
  }
  return value;
};
