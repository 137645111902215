import React from 'react';
import ApiAccessSettings from './ApiAccessSettings';
import DeviceTrackedStorefront from './DeviceTrackedStorefront';

const TrackedStorefront = props => {
  return (
    <div className={props.classes.contentBox}>
      <DeviceTrackedStorefront {...props} device={'iOS'} />
      <DeviceTrackedStorefront {...props} device={'Android'} />
      <ApiAccessSettings {...props} />
    </div>
  );
};

export default TrackedStorefront;
