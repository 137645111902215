import React from 'react';
import { SimpleFormIterator } from 'react-admin';

class RestrictedFormIterator extends SimpleFormIterator {
  maximumSizeReached = () => this.props.fields.length >= this.props.maximumSize;

  render() {
    return (
      <SimpleFormIterator
        disableAdd={this.maximumSizeReached()}
        {...this.props}
      />
    );
  }
}

export default RestrictedFormIterator;
