import gql from 'graphql-tag';
import fragments from './fragment';
import { mapForApi } from './mapApiPermissions';

export const updateOrganisationMutation = gql`
  mutation updateOrganisation(
    $id: ID!
    $name: String
    $referralCode: String
    $type: OrganisationType
    $rewardProgramme: RewardProgramme
    $status: OrganisationStatus
    $registrationUserLimit: Int
    $pocName: String
    $pocEmail: String
    $pocPhone: String
    $storefront: StorefrontInput
    $packageId: String
    $apiType: String
    $apiAccess: ApiAccessInput
  ) {
    data: updateOrganisation(
      id: $id
      name: $name
      referralCode: $referralCode
      type: $type
      rewardProgramme: $rewardProgramme
      status: $status
      registrationUserLimit: $registrationUserLimit
      pocName: $pocName
      pocEmail: $pocEmail
      pocPhone: $pocPhone
      storefront: $storefront
      packageId: $packageId
      apiType: $apiType
      apiAccess: $apiAccess
    ) {
      ...FullOrganisation
    }
  }

  ${fragments}
`;

const updateOrganisation = async ({ client, params }) => {
  try {
    // restructure the permissions data to get updated from the CheckboxGroupInput component
    params = mapForApi(params);
    const response = await client.mutate({
      mutation: updateOrganisationMutation,
      variables: params.data,
    });
    return response.data;
  } catch (e) {
    if (e.graphQLErrors && e.graphQLErrors.length) {
      const [error] = e.graphQLErrors;
      const message = error.message;
      throw new Error(message);
    }
    throw e;
  }
};

export default updateOrganisation;
