export const genderMapping = [
  { id: 'MALE', name: 'Male' },
  { id: 'FEMALE', name: 'Female' },
  { id: 'OTHER', name: 'Other' },
  { id: 'UNSPECIFIED', name: 'Unspecified' },
];

export const booleanMapping = [
  { id: false, name: 'No' },
  { id: true, name: 'Yes' },
]

export const mapValue = (map, value) => {
  const mapping = map.find(element => element.id === value);
  if (mapping) {
    return mapping.name;
  }
  return value;
};
