import React from 'react';
import PropTypes from 'prop-types';
import { FileUpload } from '@material-ui/icons';
import * as Papaparse from 'papaparse';
import { Button } from 'react-admin';

class CsvUploader extends React.Component {
  static get defaultProps() {
    return {
      show: true,
      label: 'Upload CSV',
    };
  }

  static get propTypes() {
    return {
      show: PropTypes.boolean,
      label: PropTypes.string,
      onSuccess: PropTypes.func.required,
      onError: PropTypes.func.required,
    };
  }

  onFileSelected = event => {
    event.preventDefault();
    const file = event.target.files[0];
    if (!file) {
      // User pressed cancel
      return;
    }

    Papaparse.parse(file, {
      delimiter: ',',
      skipEmptyLines: true,
      complete: result => {
        if (result.errors.length) {
          // Just use first error
          const error = result.errors[0].message;
          this.props.onError(error);
        } else {
          this.props.onSuccess(result.data);
        }
        // Clear file selector to reset
        this.fileSelector.value = null;
      },
    });
  };

  openFileSelector = () => {
    this.fileSelector.click();
  };

  render() {
    return this.props.show ? (
      <React.Fragment>
        <Button label={this.props.label} onClick={this.openFileSelector}>
          <FileUpload />
        </Button>
        <input
          ref={fileSelector => (this.fileSelector = fileSelector)}
          style={{ display: 'none' }}
          type="file"
          accept=".csv"
          name="file"
          onChange={this.onFileSelected}
        />
      </React.Fragment>
    ) : null;
  }
}

export default CsvUploader;
