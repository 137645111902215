import gql from 'graphql-tag';

export default gql`
  fragment FullOrganisation on Organisation {
    __typename
    id
    name
    referralCode
    type
    rewardProgramme
    status
    registrationUserLimit
    pocName
    pocEmail
    pocPhone
    packageId
    conditions {
      id
      name
      numberOfUsers
    }
    activities {
      id
      name
      count
    }
    countUsers
    countUsersBelow50
    countUsers50To64
    countUsersOver64
    countUsersFemale
    countUsersMale
    avgWeeklyExercise
    avgWeeklySteps
    avgWeeklyDistance
    goalsCompleted1
    goalsCompleted12
    goalsCompleted1Percentage
    goalsCompleted12Percentage
    bloodPressure0
    bloodPressure6
    bloodPressure12
    restingHeart0
    restingHeart6
    restingHeart12
    bmi0
    bmi6
    bmi12
    sixMinWalk0
    sixMinWalk6
    sixMinWalk12
    waistCircFe0
    waistCircFe6
    waistCircFe12
    waistCircMa0
    waistCircMa6
    waistCircMa12
    orders {
      id
      user {
        id
        fullName
        email
      }
      orderTotal {
        amount
        currency
      }
      orderDate
    }
    storefront {
      type
      ios {
        enabled
        url
        orderId {
          url
          strategy
          pattern
          field
        }
        total {
          url
          strategy
          pattern
          field
        }
      }
      android {
        enabled
        url
        orderId {
          url
          strategy
          pattern
          field
        }
        total {
          url
          strategy
          pattern
          field
        }
      }
    }
    apiType
    apiAccess {
      enabled
      hasApiKey
      whitelist
      webhookUrl
      webhookSignatureKey
      createUserPermission
      submitActivityPermission
      receiveActivityPermission
      submitHealthMetricPermission
      receiveHealthMetricPermission
      receiveUserRegisteredPermission
      receiveUserAppOpenedPermission
    }
  }
`;
