export const statusMapping = [
  { id: 'DRAFT', name: 'Draft' },
  { id: 'ACTIVE', name: 'Active' },
];

export const intensityMapping = [
  { id: 'LOW', name: 'Low' },
  { id: 'MEDIUM', name: 'Medium' },
  { id: 'HIGH', name: 'High' },
];

export const mapValue = (map, value) => {
  const mapping = map.find(element => element.id === value);
  if (mapping) {
    return mapping.name;
  }
  return value;
};
