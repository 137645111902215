import React from 'react';
import {
  TextInput,
  required,
  BooleanInput,
  FormDataConsumer,
} from 'react-admin';
import get from 'util/get';

const DeviceSimpleStorefront = ({ classes, device }) => {
  const deviceAccessor = device.toLowerCase();
  const isEnabled = formData =>
    Boolean(get(['storefront', deviceAccessor, 'enabled'], formData));

  return (
    <div className={classes.column}>
      <BooleanInput
        label={`${device} Storefront Enabled`}
        source={`storefront.${deviceAccessor}.enabled`}
      />
      <FormDataConsumer>
        {({ formData }) => (
          <TextInput
            label={`${device} Storefront URL`}
            source={`storefront.${deviceAccessor}.url`}
            validate={isEnabled(formData) && required()}
            disabled={!isEnabled(formData)}
          />
        )}
      </FormDataConsumer>
    </div>
  );
};

export default DeviceSimpleStorefront;
