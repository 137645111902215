import React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '200px',
  },
};

const Empty = props => {
  const { classes } = props;
  return (
    <div className={classes.container}>
      <Typography variant="h2">No {props.name}</Typography>
    </div>
  );
};

export default withStyles(styles)(Empty);
