const pathToArray = val => {
  if (typeof val === 'string') {
    return val.split('.');
  }
  return val;
};

const get = (path, record) => {
  const keys = pathToArray(path);

  let result = record;

  for (const key of keys) {
    if (!result) {
      return;
    }
    result = result[key];
  }

  return result;
};

export default get;
