import React from 'react';
import ApiAccessSettings from './ApiAccessSettings';
import DeviceSimpleStorefront from './DeviceSimpleStorefront';

const SimpleStorefront = props => {
  return (
    <div className={props.classes.contentBox}>
      <DeviceSimpleStorefront {...props} device={'iOS'} />
      <DeviceSimpleStorefront {...props} device={'Android'} />
      <ApiAccessSettings {...props} />
    </div>
  );
};

export default SimpleStorefront;
