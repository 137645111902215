import React from 'react';
import { ArrayField, Datagrid, DateField, TextField } from 'react-admin';
import get from 'util/get';
import Empty from 'CustomComponents/Empty';

const MoneyField = ({ source, record = {} }) => {
  const money = record[source];
  if (!money) return;
  const formatted = money.amount;
  return <span>{formatted}</span>;
};

class Orders extends React.Component {
  render() {
    return get('record.orders.length', this.props) > 0 ? (
      <ArrayField record={this.props.record} source="orders">
        <Datagrid>
          <DateField source="orderDate" label="Order Date" />
          <TextField source="id" label="Order ID" />
          <MoneyField source="orderTotal" label="Order Total" />
        </Datagrid>
      </ArrayField>
    ) : (
      <Empty name="Orders" />
    );
  }
}

export default Orders;
